import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(private http:HttpClient){}
  private API = 'https://astroastreea.ro/backend/api/content';
  private BackEndAssetsLocation = 'https://astroastreea.ro/backend/storage/uploads/';

 // private API = 'http://78.96.182.89:4568/backend/api/content';
 // private BackEndAssetsLocation = 'http://78.96.182.89:4568/backend/storage/uploads/';
    private cfg={
      settings:undefined,
      menu:undefined,
      welcome:undefined,
      beAsssets:this.BackEndAssetsLocation
    }

    get getCfg() {
        return this.cfg;
    }
    get getAPI(){
      return this.API
    }
    public load(): Promise<any> {
        const settings = new Promise((resolve, reject) => {
                            this.http.get(this.API+'/item/settings').subscribe((response: any) => {
                                this.cfg["settings"] = response;
                                resolve(true);
                          });
                       });
        const menu = new Promise((resolve, reject) => {
                        this.http.get(this.API+'/items/menu').subscribe((response: any) => {
                            this.cfg["menu"] = this.transformMenu(response);
                            resolve(true);
                      });
                   });
        const welcome = new Promise((resolve, reject) => {
                      this.http.get(this.API+'/item/welcome').subscribe((response: any) => {
                          this.cfg["welcome"] =response;
                          resolve(true);
                    });
                  });
        return Promise.all([settings,menu,welcome])

    }
    private transformMenu(response:any){
      const tempHold = response;
      let resp=[]
      response.forEach((link:any) => {
        if( link._pid==null)
        {
          link['children']=tempHold.filter((clink:any)=>{  
            return link._id == clink._pid
          });
          resp.push(link)     
        }
      });
      return resp;
    }
   

    /*

public load(): Promise<any> {
        return new Promise((resolve, reject) => {
          this.http.get(this.envUrl).subscribe((response: any) => {
              this.config["Settings"] = response;
              resolve(true);
        });
      });
    }
    */
}