<!--
<section class="p-b-0">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="title title2 title-inner">
                    <div class="main-title">
                        <h2 class="font-primary borders text-center main-text text-uppercase m-b-0"><span>contact 1</span></h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> 
<footer class="event contact set-relative bg bg-img bg-about p-b-0 event-gradient" id="contact"  [ngStyle]="{'background-image': 'url(assets/images/event/bg1.jpg)'}">
    <div class="container p-b-100">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title title3">
                    <div class="main-title">
                        <h2 class=" text-white">our schedule</h2>
                    </div>
                    <div class="sub-title">
                        <P class="text-white">Register now and reserve your seat for this
                            <span>year's unice,</span> the largest <span>web development</span> and online marketing
                            <span>conference in UK,</span> covering everything you need to know in order to develop a
                            successful online business.</P>
                    </div>
                </div>
            </div>
            <div class="col-xl-5 offset-xl-1 col-md-6 p-r-0 map">
                <div class="iframe-container">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2965.0824050173574!2d-93.63905729999999!3d41.998507000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sWebFilings%2C+University+Boulevard%2C+Ames%2C+IA!5e0!3m2!1sen!2sus!4v1390839289319"
                    ></iframe>
                </div>
            </div>
            <div class="col-xl-5 p-l-0 col-md-6 set-z-index form-footer">
                <div class="bg-white">

                    <form>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="name">Name *</label>
                                    <input type="text" class="form-control" id="name">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="Phone">Phone *</label>
                                    <input type="text" class="form-control" id="Phone">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="Email">Email *</label>
                                    <input type="text" class="form-control" id="Email">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="Subject">Subject *</label>
                                    <input type="text" class="form-control" id="Subject">
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="Message">Message *</label>
                            <input type="text" class="form-control" id="Message">
                        </div>

                        <a class="btn btn-default primary-btn m-0-auto event-btn ">send</a>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <img src="assets/images/event/footer/2.png" alt="" class="set-abs top-0 plane2">
    <img src="assets/images/event/footer/1.png" alt="" class="set-abs bottom-0 plane">
</footer>
-->
<section class=" bg-light-inner p-b-0">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="title title2 title-inner">
                    <div class="main-title">
                        <h2 class="font-primary borders text-center main-text text-uppercase m-b-0"><span>Formular de contact</span></h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
<section class="music bg-footer" [ngStyle]="{'background-image': 'url(assets/images/music/footer-bg.jpg)'}">
    <div class="container">
        <div class="row">
            <div class="col-sm-4">
                <div class="contact-details text-center">
                    <h4 class="contact-heading text-white ">{{this.config.email}}</h4>
            
                </div>
            </div>
            <div class="col-sm-4">
                <div class="contact-details text-center">
                    <h4 class="contact-heading text-white ">facebook/{{this.config.facebook}}</h4>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="contact-details text-center">
                    <h4 class="contact-heading  text-white">{{this.config.telefon}}</h4>
                </div>
            </div>
        </div>
        <div class="form form-footer p-t-50">
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <input type="text" placeholder="Nume" [(ngModel)]="form_fname">
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <input type="text" placeholder="Prenume" [(ngModel)]="form_lname">
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <input type="text" placeholder="Numar Telefon" [(ngModel)]="form_phone">
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <input type="text" placeholder="Adresa Email" [(ngModel)]="form_email">
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <textarea class="form-control" rows="6" placeholder="Mesaj" [(ngModel)]="form_message"></textarea>
                    </div>
                </div>
                <div class="booking">
                    <a class="btn btn-default primary-btn m-0-auto bg-black" *ngIf="!registerDone" (click)="sendContactForm()">Trimite</a>
                    <a class="btn btn-default primary-btn m-0-auto bg-black" *ngIf="registerDone" >Mesaj Trimis</a>
                </div>
            </div>
        </div>
    </div>
</section>

</section>
