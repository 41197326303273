import { Component, OnInit } from '@angular/core';
import { CustomizerService } from './../../../service/customizer.service';
import { ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { ConfigService } from 'src/app/shared/service/config.service';


@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {


  public isDarkModeOn : boolean=false;
  public currentRoute: any;
  public config:any
  
  constructor(private activatedRoute: ActivatedRoute,private router: Router, public customize: CustomizerService, private _cfg:ConfigService) {
    this.config=this._cfg.getCfg
    this.isDarkModeOn = this._cfg.getCfg.settings.darkTheme 
    router.events
    .pipe(filter((event:any) => event instanceof NavigationEnd ))
    .subscribe((val) => {
      this.currentRoute = val.url; 
    });
   
  }

  ngOnInit() {
  }

  onDayDarkModeSwicth($event:any){    
    this.customize.setLayoutVersion(this.isDarkModeOn?"dark":"light")
    this.isDarkModeOn=!this.isDarkModeOn
  }

  getAuthMenus(){
    return this.config.menu.filter((m:any) => { return m.type=='auth'});
  }
}
