import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ResumeComponent } from './layouts/resume/resume.component'

import { PagesComponent } from './pages/pages.component';
import { BlogComponent } from './blog/blog.component';
//import { ShopComponent } from './shop/shop.component'
import { MentenanceGuard } from './shared/guard/mentenance.guard'
import { MaintenanceComponent } from './pages/maintenance/maintenance.component'

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: ResumeComponent,
    canActivate: [MentenanceGuard]
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent,
  },
  {
    path: 'page',
    component: PagesComponent,
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
    data: {
      breadcrumb: "Pages"
    },
    canActivate: [MentenanceGuard]
  },
  {
    path: 'articole',
    component: BlogComponent,
    loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule),
    data: {
      breadcrumb: "Articole"
    },
    canActivate: [MentenanceGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
