import { Component, OnInit } from '@angular/core';
import { ConfigService } from './../../../shared/service/config.service'
import { WelcomeAPIService } from './../../../shared/service/restAPI/welcome-api.service'
@Component({
  selector: 'app-resume-pricing',
  templateUrl: './resume-pricing.component.html',
  styleUrls: ['./resume-pricing.component.scss']
})
export class ResumePricingComponent  {

  public db:any;
  public subscriptionList:any
  public beAssets:any;
  constructor(private configAPI:ConfigService, private welcomeAPI:WelcomeAPIService) { 
    this.db=this.configAPI.getCfg.welcome;
    this.beAssets=this.configAPI.getCfg.beAsssets;
    this.loadSubscriptionList();
  }
  loadSubscriptionList() {
    let maxSpecs=0;
    return this.welcomeAPI.listSubscription().subscribe((data:any) => {
      this.subscriptionList = data;
     
      this.subscriptionList.map((e:any) =>{
          if(maxSpecs<e.specs.length)
          {
            maxSpecs=e.specs.length
          }
      });
      this.subscriptionList.map((e:any) =>{      
        if(e.specs.length<maxSpecs)
        {
         for(let i=e.specs.length+1; i<=maxSpecs; i++)
         {
           e.specs.push("-");
         }
        }
          return e;
       });
       
    });
    
  }

  pricing = [
    { 
      icon:"rocket-ship",
      package:"free",
      feature1:"Notificari Email",
      feature2:"Access articole nivel1",
      feature3:"Free Forever",
      price:"0",
      btn:"cumpara"
    },
    {
      icon:"diamond",
      package:"Mediam",
      feature1:"pachetul Free",
      feature2:"Access articole nivel1",
      feature3:"Articole speciale",
      price:"49",
      btn:"cumpara"
    },
    {
      icon:"pie-chart",
      package:"Business",
      feature1:"pachetul Mediam",
      feature2:"Access articole nivel1",
      feature3:"Articole personalizate",
      price:"99",
      btn:"cumpara"
    }
  ]
  
  pricingcarouselOptions= {
            items: 3,
            margin: 50,
            nav: false,
            autoplay: false,
            slideSpeed: 300,
            paginationSpeed: 400,
            loop: true,
            dots: false,
            responsive: {
                0: {
                    items: 1,
                    margin: 10
                },
                600: {
                    items: 2,
                    margin: 10
                },
                1000: {
                    items: 3,
                    margin: 10
                }
            }
  }

}
