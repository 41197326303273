import { Component, OnInit } from '@angular/core';
import { ColorScssService } from '../../../shared/service/color-scss.service';
import { Gallery, GalleryItem, ImageItem, ThumbnailsPosition, ImageSize } from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';
import { BlogAPIService } from 'src/app/shared/service/restAPI/blog-api.service';
import { ConfigService } from 'src/app/shared/service/config.service';
@Component({
  selector: 'app-portfolio-gallery',
  templateUrl: './portfolio-gallery.component.html',
  styleUrls: ['./portfolio-gallery.component.scss']
})

export class PortfolioGalleryComponent implements OnInit {
  public customizer: any = "all"
  items: GalleryItem[];
  public beAssets;
  public imageData // = data;

  constructor(private blogAPI:BlogAPIService, private configAPI:ConfigService) {
    this.beAssets=this.configAPI.getCfg.beAsssets;
    this.loadLast8Posts();
  }

  loadLast8Posts() {
    return this.blogAPI.listLast8Posts().subscribe((data:any) => {
      this.imageData = data;
    });
  }
  ngOnInit() {
   //  this.items = this.imageData.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
    
  }

  openGallery(val) {
    this.customizer = val
  }

}
