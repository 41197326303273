import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ConfigService } from './../../../shared/service/config.service'

@Component({
  selector: 'app-resume-portfolio',
  templateUrl: './resume-portfolio.component.html',
  styleUrls: ['./resume-portfolio.component.scss']
})
export class ResumePortfolioComponent  {

  public db:any;
  constructor(private configAPI:ConfigService) { 
    this.db=this.configAPI.getCfg.welcome;
  }

}