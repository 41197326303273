import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot, ActivatedRoute, UrlTree } from '@angular/router';
import { ConfigService } from '../service/config.service'
import { HttpClient  } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MentenanceGuard implements CanActivate {
  public cfg:any;

  constructor(private http:HttpClient,private _cfg:ConfigService, private router: Router, private activatedRoute:ActivatedRoute) {
    this.cfg = this._cfg.getCfg.settings?this._cfg.getCfg.settings :null;
   }
   canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean|UrlTree {
      if (this.cfg.mentenance) {        
        this.http.get("https://api.ipify.org/?format=json").subscribe((res:any)=>{  
          let ipallowed = this.cfg.mentenanceIP.split(';');
          if(ipallowed.includes(res.ip)==false)
          {
            this.router.navigate(["maintenance"]);
            return false;
          }
          
        });
        
      }
      return true;
    }

  getIP(){
    return 
  }  

}
