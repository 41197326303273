import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, Observable, retry, throwError } from 'rxjs';
import { ConfigService } from '../config.service';
@Injectable({
  providedIn: 'root'
})

export class BlogAPIService {

  constructor(private http: HttpClient, private cfg : ConfigService) {}
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  

   listPosts(filter:any='{ $or :[ {main: null}, { main: false} ]}',skip:any=0,limit:any=10,sort:any='{_created: -1}'): Observable<any> {
    return this.http
      .get<any>(this.cfg.getAPI + '/items/posts?skip='+skip+'&limit='+limit+'&sort='+JSON.stringify(sort).replace(/"/g, '')+ '&filter='+JSON.stringify(filter))
      .pipe(retry(1), catchError(this.handleError));
  }
  readPost(filter:any): Observable<any> {
    return this.http
      .get<any>(this.cfg.getAPI + '/item/posts?filter='+JSON.stringify(filter))
      .pipe(retry(1), catchError(this.handleError));
  }

// other blog help
  listMainPost(): Observable<any> {
    return this.http
      .get<any>(this.cfg.getAPI + '/item/posts?sort={_created: -1}&limit=1&filter={main:true}')
      .pipe(retry(1), catchError(this.handleError));
  }
  
  // other blog help
  listPopularPosts(): Observable<any> {
    return this.http
      .get<any>(this.cfg.getAPI + '/items/posts?sort={views: -1}&filter={views:{$ne: null}}&limit=4')
      .pipe(retry(1), catchError(this.handleError));
  }
// other blog help
  listLast8Posts(): Observable<any> {
    return this.http
      .get<any>(this.cfg.getAPI + '/items/posts?sort={_created: -1}&limit=8')
      .pipe(retry(1), catchError(this.handleError));
  }




  ///Blog categoryes
  listCategoryes(f:any=""): Observable<any> {
    return this.http
      .get<any>(this.cfg.getAPI + '/items/categories'+f)
      .pipe(retry(1), catchError(this.handleError));
  }
  readCategory(id:any): Observable<any> {
    return this.http
      .get<any>(this.cfg.getAPI + '/item/categories/'+id)
      .pipe(retry(1), catchError(this.handleError));
  }
  



  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    
    return throwError(() => {
      return errorMessage;
    });
  }
}
