
<app-nav></app-nav>
<app-resume-header></app-resume-header>
<app-resume-portfolio ></app-resume-portfolio>
<app-resume-pricing  ></app-resume-pricing>

<app-resume-schedule ></app-resume-schedule>
<app-resume-services ></app-resume-services>
<app-resume-subscribe  ></app-resume-subscribe> 
<app-footer></app-footer>
