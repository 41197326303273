import { BrowserModule, Meta  } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { routes } from './app-routing.module';

import { AppComponent } from './app.component';
import { LayoutsModule } from './layouts/layouts.module'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { PagesModule } from './pages/pages.module';
import { SharedModule } from './shared/shared.module'

import { ConfigService} from './shared/service/config.service'

import { LocationStrategy, Location, PathLocationStrategy } from '@angular/common';

export function init_app(configService: ConfigService) {
  return () => configService.load();
}



@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutsModule,
    PagesModule,
    HttpClientModule,
    CarouselModule,
    SharedModule,
    RouterModule.forRoot(routes, { useHash: false, anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled'  })

  ],
  providers: [
    Location, {provide: LocationStrategy, useClass: PathLocationStrategy},
    ConfigService,
    { provide: APP_INITIALIZER, useFactory: init_app, deps: [ConfigService], multi: true },
    Meta 
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
