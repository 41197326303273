<!--services start-->
<section class="resume services bg-pink" *ngIf="db && db.services_show">
    <div class="container">
        <div class="row">
            <div class=" offset-md-2 col-md-8">
                <div class="title title2">
                    <h6 class="font-primary borders main-text text-uppercase"><span>Servicii</span></h6>
                    <div class="sub-title">
                        <div class="">
                            <h2 class="title-text">Gama de servicii disponibile</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3 col-sm-6" *ngFor="let data of service">
                <div class="service text-center bg-white">
                    <div class="img-block">
                        <img [src]="data.img" alt="" class="service-img img-fluid">
                    </div>
                    <div class="service-feature">
                        <h4 class="feature-text text-center">{{data.type}}</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur a arcu vel enim blandit ultricies. Vestibulum auctor lacus a dapibus congue. Fusce sapien purus, pharetra nec est in, ultrices fringilla nulla. Sed sit amet varius lorem. Nulla commodo metus eget neque eleifend, aliquam egestas diam pretium. Ut congue in lacus et scelerisque. Duis sollicitudin lorem libero</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--services start-->