import { Component, OnInit } from '@angular/core';
import { ConfigService } from './../../../shared/service/config.service'
import { WelcomeAPIService } from 'src/app/shared/service/restAPI/welcome-api.service';
@Component({
  selector: 'app-resume-schedule',
  templateUrl: './resume-schedule.component.html',
  styleUrls: ['./resume-schedule.component.scss']
})
export class ResumeScheduleComponent {
  public db:any;
  public eventsList:any;
  public beAssets:any;
  constructor(private configAPI:ConfigService, private welcomeAPI:WelcomeAPIService) { 
    this.db=this.configAPI.getCfg.welcome;
    this.beAssets=this.configAPI.getCfg.beAsssets;
    this.loadEventList();
  }
  loadEventList() {
    return this.welcomeAPI.listEvents().subscribe((data:any) => {
      this.eventsList =  this.groupBy(data, "date") 
  
    });
  }
  groupBy(arr, prop) {
    const map = new Map(Array.from(arr, obj => [obj[prop], []]));
    arr.forEach(obj => map.get(obj[prop]).push(obj));
    return Array.from(map.values());
  }
  
schedule = [
  {
    name:'MASTER IN WEBDESIGN',
    img:'assets/images/resume/icon/icon-resume.png',
    description:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of.',
    toTime: '08:00',
    endTime: '10:00'
  },
  {
    name:'LATEST ANIMATION',
    img:'assets/images/resume/icon/icon-resume.png',
    description:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of.',
    toTime: '08:00',
    endTime: '10:00'
  },
  {
    name:'ADVANCE DEGREE',
    img:'assets/images/resume/icon/icon-resume.png',
    description:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of.',
    toTime: '08:00',
    endTime: '10:00'
  },
  {
    name:'LATEST ANIMATION',
    img:'assets/images/resume/icon/icon-resume.png',
    description:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of.',
    toTime: '08:00',
    endTime: '10:00'
  }
]
}
