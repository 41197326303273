<section class="saas1 header" id="home">
    <div class="saas1-header bg header8-content" [ngStyle]="{'background-image': 'url(assets/images/inner-page/maintain-soon/bg-maintenance.jpg)'}">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="center-text justify-content-center text-center">
                        <div>
                            <div class="header-text">                              
                                    <div class="center-content">                                       
                                        <img src="assets/images/logo/5.png" class="img-fluid" alt="AstroAstreea.ro" style="filter: brightness(0) invert(1);">
                                    </div>
                            </div>
                            <div class="header-sub-text">
                                <h3 class="text-white"><span> Revenim imediat</span></h3>
                            </div>
                            <div class="header-sub-text">
                                <h3 class="text-white"> AstroAstreea.ro necesita cateva mici lucrari </h3>
                            </div>
                            <div class="header-sub-text">
                                <h6>Pana terminam lucrarile ne poti gasi pe facebook apasand mai jos</h6>
                            </div>
                            <div class="header-sub-text">
                                <h3 ><a href="http://facebook.com/tinebalanta " class="resume btn btn-default primary-btn back-white">  AstroAstreea Facebook </a></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <img src="assets/images/saas1/background2.png" class="img-fluid set-abs background" alt="">
</section>

