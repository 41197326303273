<!-- about section Start-->
<section class="gym format">
    <div class="container">
        <div class="row">
            <div class="col-md-8 offset-md-2">
                <div class="center-text">
                    <div class="text-center">                        
                        <div class="format-sub-text mb-0">
                            <p class="p-light about-para">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                                been the industry's standard dummy text ever since the 1500s.When an unknown printer took a galley of
                                type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text.                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- about section end-->
<!-- 
<section class="event testimonial set-relative bg about-page">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="title title2 title-inner">
                    <div class="main-title">
                        <h2 class="font-primary borders text-center text-uppercase m-b-0">
                            <span>testimonial 2</span>
                        </h2>
                    </div>
                </div>
            </div>
            <div class="col-md-10 offset-md-1 margin-b">
                <owl-carousel-o [options]="aboutTestimonialCarouselOptions" class="testimonial">
                    <ng-container *ngFor="let user of aboutTestimonial">
                        <ng-template carouselSlide >
                            <div class="p-t-30">
                            <div class="col-lg-10 offset-lg-1 col-12">
                                <div class="row">
                                    <div class="col-xl-3 col-sm-4">
                                        <div class="testimonial-msg set-relative">
                                            <img [src]="user.img" alt=""
                                                class="img-fluid avtar-img">
                                            <div class="msg-box">
                                                <div class="center-content">
                                                    <img src="assets/images/event/testimonial/message.png" alt=""
                                                        class="img-fluid set-abs">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-9 col-sm-8">
                                        <div class="quotes set-relative m-b-30">
                                            <img src="assets/images/event/testimonial/i1.png" alt=""
                                                class="img-fluid set-abs left-quote">
                                            <div class="quote-text">
                                                <h5>"When you innovate, you make mistakes.It is best to admit them
                                                    quickly,
                                                    & get on with improving your other innovations."</h5>
                                            </div>
                                            <img src="assets/images/event/testimonial/i2.png" alt=""
                                                class="img-fluid set-abs right-quote">
                                        </div>
                                        <div class="rating align-items-center">
                                            <div class="stars">
                                                <ul>
                                                    <li>
                                                        <i class="fa fa-star yellow" aria-hidden="true"></i>
                                                        <i class="fa fa-star yellow" aria-hidden="true"></i>
                                                        <i class="fa fa-star yellow" aria-hidden="true"></i>
                                                        <i class="fa fa-star yellow" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                    </li>
                                                </ul>
                                            </div>
                                            <h5 class="name">Jhon Denal - <span> UI Designer</span></h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>

<section class="event speaker set-relative" id="speaker">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="title title2 title-inner">
                    <div class="main-title">
                        <h2 class="font-primary borders text-center text-uppercase m-b-0">
                            <span  >Our team</span>
                        </h2>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-6 speker-container">
                <div class="text-center">
                    <div class="team-img">
                        <img src="assets/images/event/l3-1.png" alt="" class="img-fluid">
                        <div class="overlay"></div>
                        <div class="social">
                            <ul>
                                <li>
                                    <a href="javascript:void(0)">
                                        <i class="fa fa-facebook center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">
                                        <i class="fa fa-twitter center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">
                                        <i class="fa fa-google center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">
                                        <i class="fa fa-globe center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="employee">
                        <h5 class="e-name text-center">Vicky Smith</h5>
                        <h6 class="post text-center ">UI/UX Designer - Little Big</h6>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-6 speker-container">
                <div class="text-center">
                    <div class="team-img">
                        <img src="assets/images/event/l3-2.png" alt="" class="img-fluid">
                        <div class="overlay"></div>
                        <div class="social">
                            <ul>
                                <li>
                                    <a href="javascript:void(0)">
                                        <i class="fa fa-facebook center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">
                                        <i class="fa fa-twitter center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">
                                        <i class="fa fa-google center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">
                                        <i class="fa fa-globe center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="employee">
                        <h5 class="e-name text-center">Sam Rowling</h5>
                        <h6 class="post text-center ">Team Leader - otstrab</h6>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-6 speker-container">
                <div class="text-center">
                    <div class="team-img">
                        <img src="assets/images/event/l3-3.png" alt="" class="img-fluid">
                        <div class="overlay"></div>
                        <div class="social">
                            <ul>
                                <li>
                                    <a href="javascript:void(0)">
                                        <i class="fa fa-facebook center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">
                                        <i class="fa fa-twitter center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">
                                        <i class="fa fa-google center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">
                                        <i class="fa fa-globe center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="employee">
                        <h5 class="e-name text-center">Mark Tucker</h5>
                        <h6 class="post text-center ">App Developer - Jumpster</h6>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-6 speker-container">
                <div class="text-center">
                    <div class="team-img">
                        <img src="assets/images/event/l3-4.png" alt="" class="img-fluid">
                        <div class="overlay"></div>
                        <div class="social">
                            <ul>
                                <li>
                                    <a href="javascript:void(0)">
                                        <i class="fa fa-facebook center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">
                                        <i class="fa fa-twitter center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">
                                        <i class="fa fa-google center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">
                                        <i class="fa fa-globe center-content" aria-hidden="true"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="employee">
                        <h5 class="e-name text-center">Sam Rowling</h5>
                        <h6 class="post text-center ">Team Leader - otstrab</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
 */-->
<section class="resume counter bg-pink">
    <div class="container">
        <div class="row">
            <div class="col-xl-3 col-6 counter-container">
                <div class="counters">
                    <img src="assets/images/resume/icon/1.png" alt="" class="img-fluid counter-img">
                    <div class="counter-text">
                        <h3 class="count-text counts">1510</h3>
                        <h5 class="count-desc">Satisfied Customers</h5>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-6 counter-container">
                <div class="counters">
                    <img src="assets/images/resume/icon/2.png" alt="" class="img-fluid counter-img">
                    <div class="counter-text">
                        <h3 class="count-text counts">306</h3>
                        <h5 class="count-desc">Total Speaker</h5>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-6 counter-container">
                <div class="counters">
                    <img src="assets/images/resume/icon/3.png" alt="" class="img-fluid counter-img">
                    <div class="counter-text">
                        <h3 class="count-text counts">48502</h3>
                        <h5 class="count-desc">Hours Worked</h5>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-6 counter-container">
                <div class="counters">
                    <img src="assets/images/resume/icon/4.png" alt="" class="img-fluid counter-img">
                    <div class="counter-text">
                        <h3 class="count-text counts">12</h3>
                        <h5 class="count-desc">Awwards Winning</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<!--accordion1 section start
<section class="saas1 faq testimonial-bg">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="title title2 title-inner">
                    <div class="main-title">
                        <h2 class="font-primary borders text-center main-text m-b-0"><span class="text-uppercase">accordion with image</span>
                        </h2>
                    </div>
                </div>
            </div>
            <div class="col-md-8">
                <div class="faq-block">
                    <div class="w-100">
                        <ngb-accordion [closeOthers]="true" class="accordion faq" activeIds="static-1">
                            <ngb-panel id="static-1" class="card">
                              <ng-template ngbPanelTitle class="card-header">
                                  <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                  aria-expanded="false">
                                 <a>
                                     <div class="fa fa-angle-right rotate"></div>
                                 </a>
                                 Do I need to make a payment?
                             </div>
                              </ng-template>
                              <ng-template ngbPanelContent class="card-body">
                                  No one cares about products. People care about ideas. Is a product an idea?
                                  Noup. Is
                                  a brand? A good one is.
                              </ng-template>
                            </ngb-panel>
                            <ngb-panel id="static-2" class="card">
                                <ng-template ngbPanelTitle class="card-header">
                                    <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                    aria-expanded="false">
                                   <a>
                                       <div class="fa fa-angle-right rotate"></div>
                                   </a>
                                   Do I need to make a payment?
                               </div>
                                </ng-template>
                
                                <ng-template ngbPanelContent class="card-body">
                                    No one cares about products. People care about ideas. Is a product an idea?
                                    Noup. Is
                                    a brand? A good one is.
                                </ng-template>
                              </ngb-panel>
                              <ngb-panel id="static-3" class="card">
                                  <ng-template ngbPanelTitle class="card-header">
                                      <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                      aria-expanded="false">
                                     <a>
                                         <div class="fa fa-angle-right rotate"></div>
                                     </a>
                                     Do I need to make a payment?
                                 </div>
                                  </ng-template>
                  
                                  <ng-template ngbPanelContent class="card-body">
                                      No one cares about products. People care about ideas. Is a product an idea?
                                      Noup. Is
                                      a brand? A good one is.
                                  </ng-template>
                                </ngb-panel>
                                <ngb-panel id="static-4" class="card">
                                    <ng-template ngbPanelTitle class="card-header">
                                        <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                        aria-expanded="false">
                                       <a>
                                           <div class="fa fa-angle-right rotate"></div>
                                       </a>
                                       Do I need to make a payment?
                                   </div>
                                    </ng-template>
                    
                                    <ng-template ngbPanelContent class="card-body">
                                        No one cares about products. People care about ideas. Is a product an idea?
                                        Noup. Is
                                        a brand? A good one is.
                                    </ng-template>
                                  </ngb-panel>
                          </ngb-accordion>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="faq-img-block">
                    <img src="assets/images/saas1/faq-img.png" class="img-fluid" alt="faq-person">
                </div>
            </div>
        </div>
    </div>
</section>
accordion1 section end-->

