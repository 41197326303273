<!--subscribe start-->
<section class="resume subscribe" id="contact" *ngIf="db">
    <div class="container" *ngIf="db.newsletter_show">
        <div class="row">
            <div class="col-lg-8 offset-lg-2">
                <div class="title title2">
                    <h3 class="subscribe-head">{{db.newsletter_row1}}</h3>
                    <p class="subscribe-sub-head font-primary">{{db.newsletter_row2}}</p>
                </div>
            </div>
            <div class="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
                <div class="subscribe">
                    <div class="center-content">
                        <div class="form-group">
                            <div class="flex">
                                <input type="email" class="form-control" placeholder="Introdu adresa de email" name="email"  [(ngModel)]="inputEmail" >
                                <div class="button-primary">
                                    <button type="submit" class=" btn btn-default text-white primary-btn" (click)="registerEmail()" >
                                        
                                        <div *ngIf="!registerDone">
                                            Trimite
                                        </div>
                                        <div *ngIf="registerDone">
                                            Inregistrare efectuata !!!
                                        </div>
                                    
                                    </button>

                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section>
  <!--subscribe end-->
  