import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/shared/service/config.service';
import { ContactAPIService } from 'src/app/shared/service/restAPI/contact-api.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  public config:any

  public form_fname:any="";
  public form_lname:any="";
  public form_email:any="";
  public form_phone:any="";
  public form_message:any="";
  public registerDone:boolean=false;

  constructor(private _cfg:ConfigService,private contactAPI:ContactAPIService) {
    this.config=this._cfg.getCfg.settings
  }

  ngOnInit() {
  }

  sendContactForm(){
    if(this.form_fname && this.form_email && this.form_message ){
      let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if(this.form_email.match(mailformat))
      {
        this.contactAPI.contactForm( 
            { "firstName":this.form_fname,
              "lastName":this.form_lname,
              "email":this.form_email,
              "phone":this.form_phone,
              "message":this.form_message
            } ).subscribe((data:any) => {
          this.registerDone=true  
        });   
      }
    }
    
  }

}
