import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, Observable, retry, throwError } from 'rxjs';
import { ConfigService } from '../config.service';
@Injectable({
  providedIn: 'root'
})

export class ContactAPIService {

  constructor(private http: HttpClient, private cfg : ConfigService) {}
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  
  // blog Posts
  countEmails( ): Observable<any> {
    return this.http
      .get<any>(this.cfg.getAPI + '/item/newsletter' )
      .pipe(retry(1), catchError(this.handleError));
  }
  listEmails(f:any=""): Observable<any> {
    return this.http
      .get<any>(this.cfg.getAPI + '/item/newsletter'+f)
      .pipe(retry(1), catchError(this.handleError));
  }
  readEmail(id:any): Observable<any> {
    return this.http
      .get<any>(this.cfg.getAPI + '/item/newsletter'+id)
      .pipe(retry(1), catchError(this.handleError));
  }
  createEmail(obj:any): Observable<any> {
    return this.http
      .post<any>(this.cfg.getAPI + '/item/newsletter',{"data":obj })
      .pipe(retry(1), catchError(this.handleError));
  }



  contactForm(obj:any): Observable<any> {
    return this.http
      .post<any>(this.cfg.getAPI + '/item/contactForm',{"data":obj })
      .pipe(retry(1), catchError(this.handleError));
  }

  


  
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }
}
