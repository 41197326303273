import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageAPIService } from './../../shared/service/restAPI/page-api.service'
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent {
  pageData:any;
  constructor( private pageAPI:PageAPIService, private router: Router, private route: ActivatedRoute,private sanitizer: DomSanitizer) { 
    this.loadPageData()
    console.log(this.pageData);
  }

  loadPageData() {
    return this.pageAPI.getPage(this.route.snapshot.paramMap.get('page')).subscribe((data:any) => {
      this.pageData=data;
      this.pageData.content=this.sanitizer.bypassSecurityTrustHtml(this.pageData.content);
      console.log(this.pageData);
    });
  }

}
