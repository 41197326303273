<!--footer start-->
<footer class="resume copyright copyright-bg inner-pages-footer">
    <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="link link-horizontal  text-center mb-3">
                        <img alt="" class="img-fluid" src="assets/images/logo/5.png">
                    </div>
                </div>
                <div class="col-12">
                    <div class="link link-horizontal">
                        <ul class="justify-content-center">
                            <li *ngFor="let menu of menuItems"><a class="copyright-text" [routerLink]="menu.path">{{menu.title}}</a></li>
                            <!-- <li><a class="copyright-text" href="#">despre mine</a></li>
                            <li><a class="copyright-text" href="#">servicii</a></li> 
                            <li><a class="copyright-text" routerLink="/articole">articole </a></li>
                            <li><a class="copyright-text" routerLink="/page/utile">info</a></li>
                            <li><a class="copyright-text" routerLink="/page/contact">contact</a></li>-->
                        </ul>
                    </div>
                </div>
                <div class="col-12">
                    <div class="text-center">
                        <div class="social-link link-horizontal">
                            <ul class="justify-content-center">
                                <li><a href="https://facebook.com/tinebalanta" class="copyright-text"><i aria-hidden="true" class="fa fa-facebook"></i></a>
                                </li>
                                <!-- <li><a class="copyright-text" href="#"><i aria-hidden="true" class="fa fa-twitter"></i></a>
                                </li>
                                <li><a class="copyright-text" href="#"><i aria-hidden="true" class="fa fa-google"></i></a>
                                </li>
                                <li><a class="copyright-text " href="#"><i aria-hidden="true" class="fa fa-instagram"></i></a></li> -->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</footer>
<!--footer end--> 
<!--copyright start-->
<div class="agency copyright inner-page" >
    <div class="container">
            <div class="row">
                <div class="col-sm-6">
                    <div class="link-horizontal">
                        <ul>
                           <!-- <li>
                                <a class="copyright-text" href="#">Politica cookie</a>
                            </li>--> 
                            <li>
                                <a class="copyright-text" routerLink="/page/content/termeni-si-conditii">Termeni &amp; Conditii</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div>
                        <h6 class="copyright-text text-white text-right">Copyright ©2023 AstroAstreea.ro </h6>
                    </div>
                </div>
            </div>
        </div>
</div>
<!--copyright start-->    
<app-tap-to-top ></app-tap-to-top>

