import { Component, OnInit } from '@angular/core';
import { ConfigService } from './../../../shared/service/config.service'
import { ContactAPIService } from 'src/app/shared/service/restAPI/contact-api.service';

@Component({
  selector: 'app-resume-subscribe',
  templateUrl: './resume-subscribe.component.html',
  styleUrls: ['./resume-subscribe.component.scss']
})
export class ResumeSubscribeComponent {
  public inputEmail:any;
  public registerDone:boolean=false;
  public db:any;
  constructor(private configAPI:ConfigService,private contactAPI:ContactAPIService,) { 
    this.db=this.configAPI.getCfg.welcome;
  }

  registerEmail(){
    if(this.inputEmail){
      let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if(this.inputEmail.match(mailformat))
      {
        this.contactAPI.createEmail( {"email":this.inputEmail} ).subscribe((data:any) => {
          this.registerDone=true  
        });   
      }
    }
    
  }
}
