<!--pricing start-->
<section class="resume pricing bg-pink" id="pricing" *ngIf="db && db.subscriptions_show">
  <div class="container" >
      <div class="row">
          <div class="col-md-8 offset-md-2">
              <div class="title title2">
                  <h6 class="font-primary borders main-text text-uppercase"><span>{{db.subscriptions_row1}}</span></h6>
                  <div class="sub-title">
                      <div class="">
                          <h2>{{db.subscriptions_row2}}</h2>
                      </div>
                  </div>
              </div>
          </div>
         
          <div class="col-12">
            <owl-carousel-o [options]="pricingcarouselOptions" class="pricing-slider">
                    <ng-container *ngFor="let subscription of subscriptionList">
                        <ng-template carouselSlide class="item">
                  <div class="price-container price-margin shadows bg-white text-center">
                      <div class="price-feature-container set-relative">
                          <div class="feature-text m-r-auto" >
                              
                              <img [src]="beAssets+''+subscription.image.path" alt="" class="img-fluid " style="height:64px; width: 64px; margin:0 auto; margin-bottom: 17px;">
                              <h4 class="feature-text-heading bold text-uppercase">{{subscription.title}}</h4>
                              <hr class="set-border">
                          </div>
                          <div class="price-features">
                              <h5 class="price-feature text-center" *ngFor="let sp of subscription.specs" >{{sp}}</h5>                                  
                          </div>
                          <div class="price-value">
                              <h6 class="price text-center">$<span class="large">{{subscription.price}}</span> {{subscription.currency}} /{{subscription.pricePer}}</h6>
                          </div>
                          <a href="#" class="btn btn-default back-white">Cumpara</a>
                      </div>
                  </div>
              </ng-template>
              </ng-container>
              </owl-carousel-o>
          </div>
      
          <!--
          <div class="col-12">
                <owl-carousel-o [options]="pricingcarouselOptions" class="pricing-slider">
                        <ng-container *ngFor="let price of pricing">
                            <ng-template carouselSlide class="item">
                      <div class="price-container price-margin shadows bg-white text-center">
                          <div class="price-feature-container set-relative">
                              <div class="feature-text">
                                  <span class="flaticon-{{price.icon}} feature-icon"></span>
                                  
                                  <h4 class="feature-text-heading bold text-uppercase">{{price.package}}</h4>
                                  <hr class="set-border">
                              </div>
                              <div class="price-features">
                                  <h5 class="price-feature text-center">{{price.feature1}}</h5>
                                  <h5 class="price-feature text-center">{{price.feature2}}</h5>
                                  <h5 class="price-feature text-center">{{price.feature3}}</h5>
                              </div>
                              <div class="price-value">
                                  <h6 class="price text-center">$<span class="large">{{price.price}}</span>/month</h6>
                              </div>
                              <a href="#" class="btn btn-default back-white">{{price.btn}}</a>
                          </div>
                      </div>
                  </ng-template>
                  </ng-container>
                  </owl-carousel-o>
              </div>-->
          </div>
      </div>
</section>
<!--pricing end-->
