<!-- Nav Start  [ngStyle]"position: absolute !important;" -->
<header class="ecommerce loding-header nav-abs custom-scroll" *ngIf="currentRoute!='/'"  >
  <div class="container">
      <div class="row">
          <div class="col">
                <nav>
                    <a routerLink="/" class="d-inline-block m-r-auto"><img src="assets/images/logo/5.png" alt="" class="img-fluid"></a>
                    <app-menu></app-menu>
                    <div class="top-header-right">
                        <ul>
                            <li class="">
                                <a aria-expanded="false" >
                                    <label class="toggle-control">
                                        <input  ([ngModel])="isDarkModeOn" (change)="onDayDarkModeSwicth($event)" type="checkbox" checked="unchecked">
                                        <span class="control"></span>
                                      </label>
                                </a>
                                
                                  
                            </li>
                            
                            <li class="account" *ngIf="getAuthMenus().length" >
                                <a aria-expanded="false" data-toggle="dropdown" href="#" id="dropdownMenuButton2">
                                    <i class="icon-user"></i>
                                </a>
                                <div aria-labelledby="dropdownMenuButton2" class="dropdown-menu show dropdown-menu-right">
                                    <a *ngFor="let m of getAuthMenus()" class="dropdown-item" [routerLink]="m.path">{{m.title}}</a>
                                </div>
                            </li>
                        </ul>
                    </div>
              </nav>
              
          </div>
      </div>
  </div>
</header>
<header class="ecommerce loding-header nav-abs custom-scroll" *ngIf=" currentRoute=='/'"  style="position: absolute !important;">
    <div class="container">
        <div class="row">
            <div class="col">
                  <nav>
                      <a routerLink="/" class="d-inline-block m-r-auto"><img src="assets/images/logo/5.png" alt="" class="img-fluid"></a>
                      <app-menu></app-menu>
                      <div class="top-header-right" >
                          <ul>
                              <li class="">
                                  <a aria-expanded="false" >
                                      <label class="toggle-control">
                                          <input  ([ngModel])="isDarkModeOn" (change)="onDayDarkModeSwicth($event)" type="checkbox" checked="unchecked">
                                          <span class="control"></span>
                                        </label>
                                  </a>                                                                      
                              </li>                              
                                <li class="account" *ngIf="getAuthMenus().length" >
                                    <a aria-expanded="false" data-toggle="dropdown" href="#" id="dropdownMenuButton2">
                                        <i class="icon-user"></i>
                                    </a>
                                    <div aria-labelledby="dropdownMenuButton2" class="dropdown-menu show dropdown-menu-right">
                                        <a *ngFor="let m of getAuthMenus()" class="dropdown-item" [routerLink]="m.path">{{m.title}}</a>
                                    </div>
                                </li>
                          </ul>
                      </div>
                </nav>
                
            </div>
        </div>
    </div>
  </header>
<!-- Nav end-->
