import { Component, Input, OnInit } from '@angular/core';
import { ConfigService } from './../../../shared/service/config.service'
@Component({
  selector: 'app-resume-header',
  templateUrl: './resume-header.component.html',
  styleUrls: ['./resume-header.component.scss']
})
export class ResumeHeaderComponent {
  public db:any;
  public beAssets:any;
  constructor(private configAPI:ConfigService) { 
    this.db=this.configAPI.getCfg.welcome;
    this.beAssets=this.configAPI.getCfg.beAsssets;
  }
}
