<!--header start-->
<section class="resume header" id="header" *ngIf="db && db.header_show">
    <div class="header7-content" >
        <div class="resume bg bg-pink"  [ngStyle]="{'background-image': 'url(assets/images/resume/h7-bg-text.png)'}">
            <div class="container">        
                <div class="row" >
                    <div class="col-lg-5 col-md-6">
                        <img src="{{beAssets+''+db.header_img.path}}" alt="" class="img-fluid set-abs bottom-0 right-0">
                    </div>
                    <div class="col-lg-6 col-md-6 offset-lg-1">
                        <div class="center-text">
                            <div >
                                <div class="header-sub-text">
                                    <h5 class="text-hash text-uppercase" [innerHTML]="db.header_row1">loading... {{db.header_row1}}</h5>
                                </div>
                                <div class="header-text">
                                    <h1  [innerHTML]="db.header_row2">loading...</h1>
                                </div>
                                <div class="header-sub-text">
                                    <h4 class="font-secondary" [innerHTML]="db.header_row3">loading...</h4>
                                </div>
                                <div class="header-sub-text">
                                    <p class="font-secondary font-large" [innerHTML]="db.header_row4">loading...</p>
                                </div>
                                <div class="link-horizontal">
                                    <ul>
                                        <li *ngIf="db.header_btn_1_text">
                                            <a *ngIf="db.header_btn_1_linkType =='internal'" [routerLink]="db.header_btn_1_link" class="btn btn-default primary-btn back-white">{{db.header_btn_1_text}}</a>
                                            <a *ngIf="db.header_btn_1_linkType =='external'" [routerLink]="db.header_btn_1_link" class="btn btn-default primary-btn back-white">{{db.header_btn_1_text}}</a>
                                        </li>
                                        <li *ngIf="db.header_btn_2_text">
                                            <a *ngIf="db.header_btn_2_linkType =='internal'" [routerLink]="db.header_btn_1_link" class="btn btn-default primary-btn back-white">{{db.header_btn_2_text}}</a>
                                            <a *ngIf="db.header_btn_2_linkType =='external'" [routerLink]="db.header_btn_1_link" class="btn btn-default primary-btn back-white">{{db.header_btn_2_text}}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    </div>
  </section>
  <!--header end-->