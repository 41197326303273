import { Component } from '@angular/core';
import { CustomizerService } from './shared/service/customizer.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'AstroAstreea.ro - Astrologie transpersonala';
 // public layoutType: string = 'dark'
  constructor(public customize: CustomizerService) { 
    //this.customize.setLayoutVersion("dark")
  }
}
