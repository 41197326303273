<!--
<div class="row">
    <div class="col-12">
        <div class="filter-container isotopeFilters">
            <ul class="list-inline filter">
                <li [ngClass]="{'active': customizer == 'all' }"><a (click)="openGallery('all')">All </a></li>
                <li [ngClass]="{'active': customizer == 'fashion' }"><a (click)="openGallery('fashion')">cat 1</a>
                </li>
                <li [ngClass]="{'active': customizer == 'bags' }"><a (click)="openGallery('bags')">cat 2</a></li>
                <li [ngClass]="{'active': customizer == 'shoes' }"><a (click)="openGallery('shoes')">cat 3</a></li>
                <li [ngClass]="{'active': customizer == 'watch' }"><a (click)="openGallery('watch')">cat 4</a></li>
            </ul>
        </div>
    </div>
</div>-->
<div class="isotopeContainer row" >
    <ng-container *ngIf="customizer=='all'" >
        <figure class="col-xl-3 col-sm-6" itemprop="associatedMedia" *ngFor="let img of imageData">
            <a [routerLink]="'/articole/detalii/'+img.slug">
              <p style="text-align:center; background-color: #9700a6; color:white; padding:10px;">{{img.title}}</p>
              <img [src]="beAssets+''+img.picture_square.path" class="img-fluid" />
            </a>
          </figure>
    </ng-container>

</div>
<!-- pagination Start
<div class="pagination_sec">
    <div class="content_detail__pagination cdp">
        <ul>
            <li><a href="javascript:void(0)" class="prev"><i class="fa fa-angle-double-left" aria-hidden="true"></i></a>
            </li>
            <li><a href="javascript:void(0)" class="active cdp_i">1</a></li>
            <li><a href="javascript:void(0)" class="cdp_i">2</a></li>
            <li><a href="javascript:void(0)" class="cdp_i">3</a></li>
            <li><a href="javascript:void(0)" class="next"><i class="fa fa-angle-double-right"
                        aria-hidden="true"></i></a></li>
        </ul>
    </div>
</div> -->
<!-- pagination End -->